import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import gskLRGB from "../../../../../assets/media/logos/GSK_logo.png";
import logoMas from '../../../../../assets/media/logos/LOGO_MAS.png'
import './styles.scss'
import { PatologiasListLink } from '../PatologiasListLink'
import { InicioListLink } from '../InicioListLink';
import { CusmtomLink } from '../Link';


export const HeaderMobile = () => {
  const [show, setShow] = useState(false)
  const toggleSideBar = async () => {
    setShow(!show)
    document.querySelector('body').classList.toggle('header-mobile__no-scroll')
  }
  return (
    <>
      <div className='header-mobile align-items-center header-mobile-fixed'>
        <div className='header-logo'>
          <Link to='/' className='d-flex align-items-center'>
            <img alt='Logo' src={gskLRGB} className='gsk mr-5' />
            <img alt='Programa Mas' src={logoMas} className='programa-mas mr-5' />
          </Link>
        </div>
        <div className='d-flex align-items-center'>
          <button className='btn p-0 burger-icon mx-4' onClick={toggleSideBar}>
            <span />
          </button>
        </div>
      </div>
      <div className={`header-mobile__sideBar ${show ? 'header-mobile__sideBar--show' : ''}`}>
        <div
          className='header-menu header-menu-mobile header-menu-layout-tab header-menu-root-arrow mr-4 header-mobile__content'
        >
          <ul className='menu-nav'>
            <InicioListLink activeOnlyWhenExact onClick={toggleSideBar} />
            <PatologiasListLink onClick={toggleSideBar} />
            <CusmtomLink activeOnlyWhenExact onClick={toggleSideBar} to='/registro' label='Registro' />
            <CusmtomLink activeOnlyWhenExact onClick={toggleSideBar} to='/ingresar' label='Ingresar' />
          </ul>
        </div>
      </div>
      {show ? <div className='header-mobile__mask' onClick={toggleSideBar} /> : null}
    </>
  )
}
